import { ref } from "vue"
import { arrToKeyedObj } from "@/utils"
import api from "./api"

const users = ref({})
const fetching = ref(false)
const updating = ref(false)
const creating = ref(false)
const working = ref(false)
const fetchUsers = async (filter = {}) => {
	let { page, perPage, ...qs } = filter
	if (!page) page = 1
	if (!perPage) perPage = 50
	qs.skip = (page - 1) * perPage
	qs.limit = perPage
	fetching.value = true
	working.value = true
	try {
		const result = await api.get("admin/users/", { qs })
		if (result) {
			const { total, items } = result
			items.forEach((i) => (i.label = `${i.firstname} ${i.lastname} ${i.email} ${i.username}`))
			Object.assign(users.value, arrToKeyedObj(items))
			return {
				...filter,
				page,
				perPage,
				total,
				items,
				ids: items.map((i) => i.id)
			}
		}
	} finally {
		fetching.value = false
		working.value = false
	}
}
const fetchUser = async (userId) => {
	fetching.value = true
	working.value = true
	try {
		const result = await api.get(`admin/users/${userId}`)
		if (result) {
			Object.assign(users.value, arrToKeyedObj(result))
			return result
		}
	} finally {
		fetching.value = false
		working.value = false
	}
}
const updateUser = async (userId, json) => {
	updating.value = userId
	working.value = true
	try {
		const result = await api.put(`admin/users/${userId}`, { json })
		users.value[userId] = result
		return result
	} finally {
		updating.value = false
		working.value = false
	}
}
const createUser = async () => {
	creating.value = true
	working.value = true
	try {
		const result = await api.post(`admin/users`)
		users.value[result.id] = result
		return result
	} finally {
		creating.value = false
		working.value = false
	}
}
const deleteUser = async (userId) => {
	updating.value = userId
	working.value = true
	try {
		const result = await api.del(`admin/users/${userId}`)
		if (result) {
			delete users.value[userId]
			return userId
		}
	} finally {
		updating.value = false
		working.value = false
	}
}

const init = () => ({
	users,
	fetchUsers,
	fetchUser,
	updateUser,
	createUser,
	deleteUser,
	fetching,
	updating,
	creating,
	working
})

export default init
