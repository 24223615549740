<template>
	<div class="dynamicTree">
		<Tree
			ref="treeEl"
			:value="data"
			:indent="20"
			:ondragend="onNodeDragEnd"
			:edgeScroll="true"
			:edgeScrollTriggerMargin="100"
			edgeScrollTriggerMode="top_left_corner"
		>
			<template v-slot="{ node, path, tree }">
				<div class="verticalBar" :style="`left: ${path.length * 20}px`" />
				<div
					class="node"
					:class="{
						editing: editing === node.id,
						selected: selected === node.id,
						drop: draggingOver === node.id
					}"
					@click="select(node)"
					@dragenter="onItemDragEnter"
					@drop="onItemDrop"
					@dragover.prevent
				>
					<input
						type="checkbox"
						:checked="!node.$folded"
						:class="{ visible: node.children && node.children.length }"
						@click.stop="tree.toggleFold(node, path)"
					/>
					<input
						v-if="editing === node.id"
						type="text"
						ref="titleInputEl"
						v-model="pendingTitle"
						:disabled="editing !== node.id"
						@click.stop
					/>
					<span class="title" v-else
						><span>{{ node.title }}</span></span
					>
					<div class="ctrls">
						<template v-if="editing === node.id">
							<Richbutton theme="prim" @click.stop="save(node, path, tree)" :working="working"
								>Save</Richbutton
							>
							<button :disabled="working" @click.stop="cancelEdit(node, path, tree)">Cancel</button>
						</template>
						<template v-else-if="!editing">
							<button v-if="path.length > 1" @click.stop="edit(node)" :disabled="working">Edit</button>
							<button @click.stop="add(node, path, tree)" :disabled="working">Add</button>
							<div v-if="path.length > 1 && !working">
								<button @click.stop="destroy(node, path, tree)" class="x ml-4" />
							</div>
							<Spinner v-else-if="working" class="ml-4" />
						</template>
					</div>
				</div>
			</template>
		</Tree>
	</div>
</template>

<script>
	import { isEqual } from "lodash"
	import { ref, computed, watch, onMounted, onBeforeUnmount, nextTick } from "vue"
	import { Tree, Fold, Draggable } from "he-tree-vue"
	import { api, alertsList } from "@/store"
	import { replaceKeysDeep, arrToKeyedObj, emitter } from "@/utils"
	import Richbutton from "./Richbutton"
	import Spinner from "./Spinner"

	export default {
		name: "DynamicTree",
		components: { Tree: Tree.mixPlugins([Draggable, Fold]), Richbutton, Spinner },
		props: { fetchPath: String, modifyPath: String, rootTitle: String, lang: { type: String, default: "en" } },
		setup(props, ctx) {
			const alerts = alertsList()
			const focusTitle = () => nextTick(() => titleInputEl.value.focus())
			const data = ref([])
			const editing = ref(false)
			const working = ref(false)
			const selected = ref(null)
			const pendingTitle = ref("")
			const draggingOver = ref(null)
			const titleInputEl = ref(null)
			const treeEl = ref(null)
			const flat = computed(() => {
				const arr = []
				const flatten = (children, parentId) =>
					children.forEach((child) => {
						arr.push({ id: child.id, parentId, title: child.title })
						if (child && child.children) flatten(child.children, child.id)
					})
				flatten(data.value)
				const o = arrToKeyedObj(arr)
				o.root = arr.length ? arr[0].id : null
				o.selected = selected.value
				return o
			})
			const fetch = () =>
				api.get(`admin/${props.fetchPath}/${props.lang}`).then((res) => {
					const parsed = replaceKeysDeep(res.folders, { subordinates: "children" })
					parsed.title = props.rootTitle
					data.value = [parsed]
				})
			const save = (node, path, tree) => {
				working.value = true
				if (node.id !== "pending") {
					const payload = { title: pendingTitle.value }
					api.put(`admin/${props.modifyPath}/${node.id}/${props.lang}`, { json: payload })
						.then((res) => {
							node.title = res.title
						})
						.finally(() => {
							editing.value = false
							working.value = false
						})
				} else {
					const parent = tree.getNodeParentByPath(path)
					const payload = { title: pendingTitle.value, parentId: parent.id }
					api.post(`admin/${props.modifyPath}/${props.lang}`, { json: payload })
						.then((res) => {
							node.id = res.id
							node.title = res.title
						})
						.finally(() => {
							editing.value = false
							working.value = false
						})
				}
			}
			const destroy = (node, path, tree) => {
				working.value = true
				api.del(`admin/${props.modifyPath}/${node.id}`)
					.then(() => tree.removeNodeByPath(path))
					.catch(() => alerts.push("Cannot delete folder", "neg"))
					.finally(() => (working.value = false))
			}
			const select = (node) => {
				if (editing.value) return
				selected.value = node.id === selected.value ? null : node.id
				if (editing.value) cancelEdit(node)
			}
			const add = (node) => {
				if (!node.children) node.children = []
				node.children.unshift({ id: "pending", title: "Untitled" })
				editing.value = "pending"
				pendingTitle.value = "Untitled"
				focusTitle()
			}
			const edit = (node) => {
				selected.value = false
				editing.value = node.id
				pendingTitle.value = node.title
				focusTitle()
			}
			const cancelEdit = (node, path, tree) => {
				if (editing.value === "pending") tree.removeNodeByPath(path)
				editing.value = false
			}
			const onNodeDragEnd = (tree, str) => {
				const start = str.startPath
				const target = str.targetPath
				if (target.length === 1 || isEqual(start, target)) return false
				const orig = tree.getNodeByPath(start)
				const index = target.at(-1)
				if (index > 0) {
					const n = [...target]
					const last = n.pop()
					n.push(last - 1)
					const node = tree.getNodeByPath(n)
					api.put(`admin/${props.modifyPath}/${orig.id}/toRightOf/${node.id}`)
				} else if (index === 0) {
					const parent = tree.getNodeParentByPath(target)
					if (!parent.children || !parent.children.length) {
						api.put(`admin/${props.modifyPath}/${orig.id}/into/${parent.id}`)
					} else {
						const node = tree.getNodeByPath(target)
						api.put(`admin/${props.modifyPath}/${orig.id}/toLeftOf/${node.id}`)
					}
				}
			}
			const onItemDragEnter = (e) => {
				const node = treeEl.value.getNodeByBranchEl(e.target.closest(".tree-branch"))
				draggingOver.value = node.id
			}
			const onItemDragEnd = () => (draggingOver.value = null)
			const onItemDrop = (e) => {
				const node = treeEl.value.getNodeByBranchEl(e.target.closest(".tree-branch"))
				emitter.emit("itemDropped", { id: node.id })
			}
			watch(() => props.lang, fetch)
			watch(flat, (a, b) => {
				if (!isEqual(a, b)) ctx.emit("update", a)
			})
			onMounted(() => {
				document.addEventListener("dragend", onItemDragEnd)
				fetch()
			})
			onBeforeUnmount(() => {
				document.removeEventListener("dragend", onItemDragEnd)
			})
			return {
				data,
				add,
				save,
				destroy,
				select,
				selected,
				edit,
				cancelEdit,
				editing,
				working,
				onNodeDragEnd,
				onItemDragEnter,
				onItemDrop,
				draggingOver,
				pendingTitle,
				titleInputEl,
				treeEl
			}
		}
	}
</script>

<style lang="scss">
	.dynamicTree {
		.node {
			position: relative;
			display: flex;
			align-items: center;
			min-height: $ctrl-ht;
			input[type="checkbox"] {
				position: relative;
				z-index: 10;
				visibility: hidden;
				&.visible {
					visibility: visible;
				}
				&:before {
					position: absolute;
					top: -100%;
					left: -100%;
					width: 300%;
					height: 300%;
					content: "";
					background: none;
				}
			}
			input[type="text"] {
				flex: 1;
				background: transparent;
			}
			.title {
				position: relative;
				z-index: 5;
				display: flex;
				flex: 0;
				white-space: nowrap;

				> span {
					position: relative;
					display: flex;
					align-items: center;
					min-height: $ctrl-ht;
					@include ph(6);
					@include pv(2);
					margin-left: -9px;
					cursor: grab;
				}
			}
			.ctrls {
				opacity: 0;
				pointer-events: none;
				margin-right: auto;
				flex: 1;
				> button:not(.x) {
					margin-left: 1px;
					@include ph(6);
					flex: 0;
				}
			}
			&:hover {
				.title,
				.title > span {
					background: $clr-neut-l1;
				}
				.ctrls {
					opacity: 1;
					pointer-events: auto;
				}
			}
			&.editing {
				> input[type="text"] {
					position: relative;
					z-index: 5;
					background: white;
					@include ba;
					@include ph(6);
					margin-left: -9px;
				}
				> .ctrls {
					opacity: 1;
					pointer-events: auto;
				}
			}
			&.selected {
				.title > span {
					color: #fff;
					background: $grd-prim;
				}
			}
			&.drop {
				.title > span {
					color: $clr-drk;
					background: $clr-fcs;
				}
			}
			&:after {
				position: absolute;
				top: 0;
				left: 9px;
				width: 1px;
				height: 100%;
				background: $clr-brdr;
				content: "";
			}
			&:before {
				position: absolute;
				top: 50%;
				left: 9px;
				width: 8px;
				height: 1px;
				background: $clr-brdr;
				content: "";
			}
		}

		.verticalBar {
			position: absolute;
			top: 0;
			width: 1px;
			height: 100%;
			background: $clr-brdr;
			margin-left: -11px;
		}

		.tree-branch {
			position: relative;
			&:last-child {
				> div > div > .verticalBar {
					display: none;
				}
				> div > div > .node:after {
					height: 50%;
				}
			}
			&:only-child {
				> * > * > .verticalBar {
					display: none;
				}
			}
		}

		.tree-placeholder-node {
			background: $clr-acc;
			height: $ctrl-ht;
			margin-left: 9px;
			position: relative;
		}

		.dragging .tree-node-back:hover {
			background-color: inherit;
		}

		.he-tree--hidden {
			display: none;
		}
	}
</style>
