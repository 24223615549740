import { reactive, toRefs, readonly } from "vue"
import api from "./api"

const init = (o = {}) => ({
	email: o.email || "",
	accounts: !!o.accounts,
	exercises: !!o.exercises,
	categories: !!o.categories,
	tags: !!o.tags,
	checks: !!o.checks,
	clients: !!o.clients,
	education: !!o.education
})

const attrs = reactive(init())

const parseFetch = (o) => Object.assign(attrs, init(o))

const actions = {
	setAttr: (attr, val) => (attrs[attr] = val),
	fetch: () => api.get("admin/profile").then(parseFetch)
}

export default () => ({
	...toRefs(readonly(attrs)),
	...actions
})
