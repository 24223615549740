import { caller, poll } from "./"

const e = (msg, data) => new CustomEvent("status", { detail: { msg, data } })

export default class Upload extends EventTarget {
	constructor(file, type) {
		super()
		if (file.type.includes("image") && !file.type.includes("png")) throw new Error("Only PNG allowed")
		this.file = file
		if (type) this.type = type
		else if (file.type.includes("image")) this.type = "image"
		else if (file.type.includes("video")) this.type = "video"
		else this.type = "attachment"
	}
	start() {
		this.dispatchEvent(e("Uploading"))
		return caller
			.get(`admin/resources/upload/${this.type}`)
			.then(({ pendingResource, uploadUrl }) =>
				fetch(uploadUrl, { method: "PUT", body: this.file })
					.then(() => {
						this.dispatchEvent(e("Processing", pendingResource))
						return poll(pendingResource.readyId)
					})
					.then(() => pendingResource)
			)
			.then((pendingResource) => this.dispatchEvent(e("Done", pendingResource)))
			.catch((err) => {
				this.dispatchEvent(e("Failed"))
				throw err
			})
	}
}
