<template>
	<div class="nav">
		<template v-if="str.accounts.value">
			<router-link to="/stats" :class="{ active: route.name === 'Stats' }">Stats</router-link>
			<router-link to="/organizations" :class="{ active: route.name === 'Organizations' }"
				>Organizations</router-link
			>
			<router-link to="/users" :class="{ active: route.name === 'Users' }">Users</router-link>
			<router-link to="/clients" :class="{ active: route.name === 'Clients' }">Clients</router-link>
			<router-link to="/folders" :class="{ active: route.name === 'Folders' }">Folders</router-link>
		</template>
		<template v-if="str.exercises.value">
			<router-link to="/exercises" :class="{ active: route.name === 'Exercises' }">Exercises</router-link>
			<router-link to="/categories" :class="{ active: route.name === 'Categories' }">Categories</router-link>
			<router-link to="/checks" :class="{ active: route.name === 'Checks' }">Checks</router-link>
			<router-link to="/tags" :class="{ active: route.name === 'Tags' }">Tags</router-link>
		</template>
		<router-link v-if="str.education.value" to="/education/en" :class="{ active: route.name === 'Education' }"
			>Education</router-link
		>
		<span class="email">{{ str.email.value }}</span>
		<Spinner v-if="api.working.value" class="mr-4" />
		<a
			v-if="str.exercises.value"
			:href="`${apiPath}/admin/exercises/rebuildsearch`"
			target="_blank"
			class="rebuildSearch"
			>Rebuild Search</a
		>
		<a @click="logout" href="#">Logout</a>
	</div>
	<router-view class="main" />
	<Modals />
	<Alerts />
</template>

<script>
	import "vue-select/dist/vue-select.css"
	import { watch, onMounted, ref } from "vue"
	import { useRoute } from "vue-router"
	import { profile, api, alertsList, modals } from "@/store"
	import { Alerts, Modals, Spinner } from "@/components"

	export default {
		name: "App",
		components: { Alerts, Modals, Spinner },
		setup() {
			const alerts = alertsList()
			const route = useRoute()
			const apiPath = ref(process.env.VUE_APP_API_PATH || `https://${window.location.hostname}/api`)
			watch(api.errs, (a) => {
				if (a) alerts.push("Oh noes! " + a, "neg")
			})
			const str = profile()
			const logout = () => api.post("logout").then(() => (window.location = "https://simpleset.net"))
			onMounted(() => {
				const redirect = localStorage.getItem("redirect")
				if (redirect) {
					localStorage.setItem("redirect", "")
					setTimeout(() => (window.location.href = redirect), 0)
				}
			})
			return { str, api, logout, route, apiPath }
		}
	}
</script>

<style lang="scss">
	@import "@/assets/scss/base.scss";
	body {
		background: $clr-pnl1;
	}
	#app {
		width: 100%;
		height: 100%;
		display: grid;
		grid-template-rows: auto 1fr;
		> .nav {
			display: flex;
			align-items: center;
			a {
				display: flex;
				align-items: center;
				height: $ctrl-ht;
				@include ph(5);
				@include bgGrdPrim(true);
				&.active {
					background: $grd-acc;
				}
				&.router-link-active {
					background: $grd-acc;
				}
				&.router-link-exact-active {
					background: $grd-acc;
				}
				&.rebuildSearch {
					background: $grd-acc;
				}
			}
			.email {
				display: block;
				@include ml(4);
				margin-right: auto;
			}
		}
		> .main {
			@include ph(5);
			@include pt(5);
			position: relative;
			overflow: scroll;
		}
	}
</style>
