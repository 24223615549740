<template>
	<div class="alertsWrapper">
		<transition-group name="alerts" tag="div">
			<div
				v-for="alert in list"
				:key="alert.id"
				class="alert"
				:class="`bgGrd${alert.theme.charAt(0).toUpperCase()}${alert.theme.slice(1)}`"
			>
				{{ alert.msg }}
			</div>
		</transition-group>
	</div>
</template>

<script>
	import { alertsList } from "@/store"

	export default {
		name: "Alerts",
		props: {
			theme: { type: String, default: "" }
		},
		setup(props) {
			return { ...alertsList() }
		}
	}
</script>

<style lang="scss">
	.alertsWrapper {
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -100px;
		width: 200px;
		pointer-events: none;
		z-index: 100;
		.alert {
			transition: all 0.8s ease;
			height: $ctrl-ht;
			width: 100%;
			text-align: center;
			line-height: $ctrl-ht;
			@include ph(5);
			&:first-child {
				border-top-left-radius: $rds-std;
				border-top-right-radius: $rds-std;
			}
		}
		.alerts-enter-from,
		.alerts-leave-to {
			opacity: 0;
			transform: translateY(30px);
		}
		.alerts-leave-active {
			position: absolute;
			z-index: 1;
		}
	}
</style>
