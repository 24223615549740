import { ref } from "vue"
import { arrToKeyedObj } from "@/utils"
import api from "./api"

const folders = ref({})
const links = ref([])
const fetching = ref(false)
const working = ref(false)
const fetchFolders = async (qs = {}) => {
	fetching.value = true
	working.value = true
	try {
		const result = await api.get("admin/folders/", { qs })
		if (result) {
			const items = result.folders || result.items
			Object.assign(folders.value, arrToKeyedObj(items))
			links.value = result.links || []
			return {
				...qs,
				folders: items,
				links: result.links,
				ids: items.map((i) => i.id)
			}
		}
	} finally {
		fetching.value = false
		working.value = false
	}
}
const fetchFolder = async (folderId) => {
	fetching.value = true
	working.value = true
	try {
		const result = await api.get(`admin/folders/${folderId}`)
		if (result) {
			//Object.assign(users.value, arrToKeyedObj(result))
			return result
		}
	} finally {
		fetching.value = false
		working.value = false
	}
}

const init = () => ({
	folders,
	links,
	fetchFolders,
	fetchFolder,
	fetching,
	working
})

export default init
