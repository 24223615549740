<template>
	<SlickList axis="xy" v-model:list="listIds" class="resourceCaptions">
		<SlickItem v-for="(resourceCaption, i) in items" :key="resourceCaption.cid" :index="i" class="resourceCaption">
			<div class="preview">
				<template v-if="resourceCaption.resourceId">
					<img :src="resources.select(resourceCaption.resourceId).url" />
					<button class="x" @click="pop(resourceCaption.cid)" tabindex="-1" />
				</template>
				<div
					v-else-if="['Uploading', 'Processing'].includes(resourceCaption.uploadStatus)"
					class="spinnerWrapper ctrls"
				>
					<div class="status ml-5 mr-a">{{ resourceCaption.uploadStatus }}</div>
					<Spinner class="mr-5" />
				</div>
			</div>
			<textarea class="caption" v-model="resourceCaption.caption" placeholder="Caption..." />
		</SlickItem>
		<Upload @upload="upload" key="upload" :multiple="true" :max="5 - items.length" class="static ba" />
	</SlickList>
</template>

<script>
	import { SlickList, SlickItem } from "vue-slicksort"
	import { resources } from "@/store"
	import Spinner from "./Spinner"
	import Upload from "./Upload"

	export default {
		name: "Resources",
		components: { SlickList, SlickItem, Spinner, Upload },
		props: ["str"],
		setup(props, context) {
			const str = resources()
			return { ...props.str }
		}
	}
</script>

<style lang="scss">
	.resourceCaptions {
		position: relative;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
		grid-gap: $atom * 5;
		@include mt(5);
	}
	.resourceCaption {
		@include ba;
		z-index: 10;
		> .preview {
			width: 100%;
			padding-top: 75%;
			background: $clr-neut-l1;
			position: relative;
			cursor: grab;
			img {
				@include absTl;
				@include fill;
				object-fit: cover;
			}
			button {
				@include absTr;
				@include mt(2);
				@include mr(2);
			}
			.spinnerWrapper {
				@include absTl;
				width: 100%;
				.status {
					font-size: $fnt-sml;
					color: $clr-prim;
					font-weight: bold;
				}
				.spinner {
					flex: 0;
					flex-basis: 24px;
				}
			}
		}
		> .caption {
			width: 100%;
			@include pa(5);
		}
	}
</style>
