import { ref } from "vue"
import { arrToKeyedObj } from "@/utils"
import api from "./api"

const clients = ref({})
const fetching = ref(false)
const updating = ref(false)
const creating = ref(false)
const working = ref(false)
const fetchClients = async (filter = {}) => {
	let { page, perPage, ...qs } = filter
	if (!page) page = 1
	if (!perPage) perPage = 50
	qs.skip = (page - 1) * perPage
	qs.limit = perPage
	fetching.value = true
	working.value = true
	try {
		const result = await api.get("admin/clients/", { qs })
		if (result) {
			const { total, items } = result
			items.forEach((i) => (i.label = `${i.firstname} ${i.lastname} ${i.email} ${i.username}`))
			Object.assign(clients.value, arrToKeyedObj(items))
			return {
				...filter,
				page,
				perPage,
				total,
				items,
				ids: items.map((i) => i.id)
			}
		}
	} finally {
		fetching.value = false
		working.value = false
	}
}
const fetchClient = async (clientId) => {
	fetching.value = true
	working.value = true
	try {
		const result = await api.get(`admin/clients/${clientId}`)
		if (result) {
			Object.assign(clients.value, arrToKeyedObj(result))
			return result
		}
	} finally {
		fetching.value = false
		working.value = false
	}
}
const updateClient = async (clientId, json) => {
	updating.value = clientId
	working.value = true
	try {
		const result = await api.put(`admin/clients/${clientId}`, { json })
		clients.value[clientId] = result
		return result
	} finally {
		updating.value = false
		working.value = false
	}
}
const deleteClient = async (clientId) => {
	updating.value = clientId
	working.value = true
	try {
		const result = await api.del(`admin/clients/${clientId}`)
		if (result) {
			delete clients.value[clientId]
			return clientId
		}
	} finally {
		updating.value = false
		working.value = false
	}
}

const init = () => ({
	clients,
	fetchClients,
	fetchClient,
	updateClient,
	deleteClient,
	fetching,
	updating,
	creating,
	working
})

export default init
