import { pick } from "lodash"
import { ref } from "vue"
import { Upload } from "@/utils"

const init = () => {
	const resources = ref({})
	const select = (resourceId) => resources.value[resourceId]
	const push = (...items) => {
		if (!items.length) return
		items.forEach((item) => {
			resources.value[item.id] = pick(item, ["id", "type", "url"])
		})
	}
	const upload = (file) => {
		const upload = new Upload(file)
		upload.addEventListener("status", ({ detail }) => {
			if (detail.msg === "Done") {
				push(detail.data)
			}
		})
		return upload
	}
	return {
		upload,
		select,
		push
	}
}

const resources = init()

export default () => resources
