import { createRouter, createWebHashHistory } from "vue-router"

const routes = [
	{
		path: "/stats",
		name: "Stats",
		component: () => import(/* webpackChunkName: "stats" */ "../views/Stats")
	},
	{
		path: "/users/:id?",
		name: "Users",
		component: () => import(/* webpackChunkName: "users" */ "../views/Users")
	},
	{
		path: "/organizations/:id?",
		name: "Organizations",
		component: () => import(/* webpackChunkName: "organizations" */ "../views/Organizations")
	},
	{
		path: "/folders",
		name: "Folders",
		component: () => import(/* webpackChunkName: "folders" */ "../views/Folders")
	},
	{
		path: "/clients",
		name: "Clients",
		component: () => import(/* webpackChunkName: "clients" */ "../views/Clients")
	},
	{
		path: "/exercises/:ids?",
		name: "Exercises",
		component: () => import(/* webpackChunkName: "exercises" */ "../views/Exercises")
	},
	{
		path: "/categories",
		name: "Categories",
		component: () => import(/* webpackChunkName: "categories" */ "../views/Categories")
	},
	{
		path: "/tags",
		name: "Tags",
		component: () => import(/* webpackChunkName: "tags" */ "../views/Tags")
	},
	{
		path: "/checks",
		name: "Checks",
		component: () => import(/* webpackChunkName: "checks" */ "../views/Checks")
	},
	{
		path: "/education/:lang/:id?",
		name: "Education",
		component: () => import(/* webpackChunkName: "education" */ "../views/Education")
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
