import { caller } from "./"

export default (readyId) => {
	const stopTime = Number(new Date()) + 1000 * 60 * 15
	const check = (resolve, reject) =>
		setTimeout(() => {
			caller
				.get(`file/${readyId}/ready`)
				.then(({ ready }) => {
					if (ready) resolve()
					else if (Number(new Date()) < stopTime) check(resolve, reject)
					else reject()
				})
				.catch(reject)
		}, 1000)
	return new Promise(check)
}
