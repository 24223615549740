<template>
	<div class="pagination">
		<div v-if="page > 1" @click.stop="$emit('click', page - 1)" class="prev">&lt;</div>
		<template v-for="(p, index) in pages" :key="p">
			<div v-if="index && p - pages[index - 1] > 1" class="ellipsis" @click.stop>...</div>
			<div @click.stop="$emit('click', p)" class="page" :class="{ selected: p === page }">{{ p }}</div>
		</template>
		<div v-if="page < totalPages" @click.stop="$emit('click', page + 1)" class="next">&gt;</div>
	</div>
</template>

<script>
	import { computed } from "vue"

	export default {
		name: "Pagination",
		props: {
			totalPages: Number,
			page: Number,
			gutter: { type: Number, default: 3 }
		},
		setup(props) {
			const margin = 1
			const pages = computed(() => {
				const list = []
				for (let page = 1; page <= props.totalPages; page++) {
					if (
						page <= props.gutter ||
						Math.abs(props.page - page) <= margin ||
						page > props.totalPages - props.gutter
					)
						list.push(page)
				}
				return list
			})
			return { pages }
		}
	}
</script>

<style lang="scss">
	.pagination {
		display: flex;
		align-items: center;
		> div {
			@include ph(3);
			height: 24px;
			border-radius: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.page,
		.prev,
		.next {
			cursor: pointer;
		}
		.ellipsis {
			@include ph(3);
		}
		.selected {
			cursor: auto;
			font-weight: bold;
			@include bgGrdPrim;
		}
	}
</style>
