import { uniq, without } from "lodash"
import { ref } from "vue"
import { arrToKeyedObj } from "@/utils"
import api from "./api"

const exercises = ref({})
const selected = ref([])
const fetching = ref(false)
const creating = ref(false)
const updating = ref(false)
const working = ref(false)

const pushSelected = (exerciseId) => (selected.value = uniq([exerciseId, ...selected.value]))
const popSelected = (exerciseId) => (selected.value = without(selected.value, exerciseId))
const toggleSelected = (exerciseId) =>
	selected.value.includes(exerciseId) ? popSelected(exerciseId) : pushSelected(exerciseId)

const fetchExercises = async (filter = {}) => {
	let { page, perPage, ...qs } = filter
	if (!page) page = 1
	if (!perPage) perPage = 50
	qs.skip = (page - 1) * perPage
	qs.limit = perPage
	fetching.value = true
	working.value = true
	try {
		const result = await api.get("admin/exercises/", { qs })
		if (result) {
			const { total, items } = result
			Object.assign(exercises.value, arrToKeyedObj(items))
			return {
				...filter,
				page,
				perPage,
				total,
				items,
				ids: items.map((i) => i.id)
			}
		}
	} finally {
		fetching.value = false
		working.value = false
	}
}
const toggleVisible = async (exerciseId) => {
	updating.value = exerciseId
	working.value = exerciseId
	try {
		const visible = !exercises.value[exerciseId].visible
		const json = { visible }
		const result = await api.put(`admin/exercises/${exerciseId}/en`, { json })
		if (result) exercises.value[exerciseId].visible = result.visible
	} finally {
		updating.value = false
		working.value = false
	}
}
const duplicate = async (exerciseId) => {
	updating.value = exerciseId
	working.value = exerciseId
	try {
		const result = await api.post(`admin/exercises/${exerciseId}/duplicate`)
		return result
	} finally {
		updating.value = false
		working.value = false
	}
}
const create = async () => {
	creating.value = true
	working.value = true
	try {
		const json = {
			title: "Untitled",
			instructions: `
				<p><b>Preparation:</b></p>
				<ul><li><p>Step</p></li></ul>
				</<p><b>Execution:</b></p>
				<ul><li><p>Step</p></li></ul>
			`
		}
		const result = await api.post(`admin/exercises/en`, { json })
		return result
	} finally {
		creating.value = false
		working.value = false
	}
}
const bump = async (exerciseId) => {
	updating.value = exerciseId
	working.value = exerciseId
	try {
		const result = await api.put(`admin/exercises/${exerciseId}/bump`)
		return result
	} finally {
		updating.value = false
		working.value = false
	}
}
const del = async (exerciseId) => {
	updating.value = exerciseId
	working.value = exerciseId
	try {
		await api.del(`admin/exercises/${exerciseId}`)
		return exerciseId
	} finally {
		updating.value = false
		working.value = false
	}
}

const init = () => ({
	exercises,
	fetchExercises,
	toggleVisible,
	fetching,
	creating,
	updating,
	working,
	selected,
	pushSelected,
	popSelected,
	toggleSelected,
	duplicate,
	bump,
	create,
	del
})

export default init
