<template>
	<div class="upload" :class="{ disabled: !max }">
		<input ref="file" v-on:change="handleUpload" type="file" :accept="accept" :multiple="multiple" />
		<div class="dropArea" @click="$refs.file.click()" @drop="handleDrop" @dragenter.prevent @dragover.prevent>
			<slot />
		</div>
	</div>
</template>
<script>
	import { ref } from "vue"

	export default {
		name: "Upload",
		props: {
			accept: { type: String, default: ".png,.mov" },
			multiple: { type: Boolean, default: false },
			max: { type: Number, default: 5 }
		},
		setup(props, context) {
			const file = ref(null)
			const handleUpload = () => {
				if (file.value.files.length <= props.max) context.emit("upload", Array.from(file.value.files))
			}
			const handleDrop = (e) => {
				e.preventDefault()
				context.emit("upload", Array.from(e.dataTransfer.files))
			}
			return {
				handleDrop,
				handleUpload,
				file
			}
		}
	}
</script>

<style lang="scss">
	.upload {
		min-height: $ctrl-ht * 2;
		&.disabled {
			opacity: 0.4;
			pointer-events: none;
		}
		input {
			display: none;
		}
		.dropArea {
			display: flex;
			align-items: center;
			justify-content: center;
			background: $clr-neut-l1;
			outline: 2px dashed $clr-neut-d2;
			outline-offset: -$atom * 3;
			height: 100%;
			min-height: $ctrl-ht * 2;
			cursor: pointer;
			text-align: center;
		}
	}
</style>
