import ky from "ky"

const globalOpts = {
	prefixUrl: process.env.VUE_APP_API_PATH || "/api",
	timeout: 1000 * 15,
	hooks: {
		//	beforeRetry: [() => ky.stop]
	}
}

const race = {}

const doReq = (method, route, opts = {}) => {
	const { qs, ...rest } = opts
	return ky[method](route, { searchParams: qs, ...rest, ...globalOpts }).json()
}

export default {
	get: (route, opts) => {
		if (race[route]) race[route].abort()
		const controller = new AbortController()
		const { signal } = controller
		race[route] = controller
		return doReq("get", route, { signal, ...opts }).finally(() => (race[route] = false))
	},
	post: (route, opts) => doReq("post", route, opts),
	put: (route, opts) => doReq("put", route, opts),
	del: (route, opts) => doReq("delete", route, opts)
}
