import { ref, computed, readonly } from "vue"
import { arrToKeyedObj } from "@/utils"
import { filterItems, selectItems } from "./helpers"
import api from "./api"

const init = () => {
	const { total, page, totalPages, search, getQS } = filterItems()
	const { pushSelected, clearSelected, isSelected } = selectItems(false)
	const education = ref({})
	const listIds = ref([])
	const folderId = ref(null)
	const lang = ref("en")
	const fetching = ref(false)
	const creating = ref(false)
	const updating = ref(false)
	const destroying = ref(false)
	const fetch = (filter = {}) => {
		const _page = filter.page || 1
		const _search = "search" in filter ? filter.search : search.value
		const _folderId = "folderId" in filter ? filter.folderId : folderId.value
		const _lang = "lang" in filter ? filter.lang : lang.value
		const qs = getQS({ page: _page, search: _search })
		if (_folderId) qs.folderId = _folderId
		fetching.value = true
		return api
			.get(`admin/education/${_lang}`, { qs })
			.then((result) => {
				total.value = result.total
				page.value = _page
				search.value = _search
				folderId.value = _folderId
				lang.value = _lang
				education.value = arrToKeyedObj(result.items)
				listIds.value = result.items.map((item) => item.id)
			})
			.catch(() => {})
			.finally(() => (fetching.value = false))
	}
	const toggleVisible = (itemId) => {
		updating.value = itemId
		return api
			.put(`admin/education/${itemId}`, { json: { visible: !education.value[itemId].visible } })
			.then((result) => (education.value[itemId].visible = result.visible))
			.catch(() => {})
			.finally(() => (updating.value = false))
	}
	const moveToFolder = (itemId, folderId) => {
		updating.value = itemId
		return api
			.put(`admin/education/${itemId}`, { json: { folderId } })
			.then(() => fetch())
			.catch(() => {})
			.finally(() => (updating.value = false))
	}
	const create = (folderId) => {
		creating.value = true
		return api
			.post("admin/education/en", { json: { folderId } })
			.catch(() => {})
			.finally(() => (creating.value = false))
	}
	const destroy = (itemId) => {
		destroying.value = itemId
		return api
			.del(`admin/education/${itemId}`)
			.catch(() => {})
			.finally(() => (destroying.value = false))
	}
	return {
		total: readonly(total),
		page: readonly(page),
		totalPages,
		items: computed(() => listIds.value.map((id) => education.value[id])),
		fetching,
		creating,
		updating,
		destroying,
		fetch,
		create,
		toggleVisible,
		moveToFolder,
		destroy,
		pushSelected,
		clearSelected,
		isSelected,
		api
	}
}

export default init
