import { ref, computed } from "vue"
import { replaceKeysDeep } from "@/utils"
import api from "./api"

const data = ref([])
const rootId = ref(null)

const flat = computed(() => {
	const o = {}
	const fn = (node, parent) => {
		o[node.id] = node
		if (parent) o[node.id].parentId = parent.id
		if (node.children) node.children.forEach((child) => fn(child, node))
	}
	data.value.forEach(fn)
	return o
})

const getPath = (nodeId) => {
	const path = []
	const fn = (nodeId) => {
		const node = flat.value[nodeId]
		if (!node) return
		path.unshift(node)
		if (node.parentId) fn(node.parentId)
	}
	fn(nodeId)
	return path
}

const fetch = () =>
	api.get(`admin/exercises/categories`).then((res) => {
		const parsed = replaceKeysDeep(res, { subordinates: "children" })
		rootId.value = parsed.id
		data.value = parsed.children
	})

export default () => ({
	fetch,
	data,
	flat,
	rootId,
	getPath
})
