<template>
	<div class="spinner">
		<svg viewBox="0 0 30 30">
			<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="15" cy="15" r="12"></circle>
		</svg>
	</div>
</template>

<style lang="scss">
	$offset: 74;
	$duration: 1.4s;

	.spinner {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 24px;
		height: 24px;
		stroke: $clr-prim-l1;

		> svg {
			animation: rotator $duration linear infinite;
			width: 100%;
			height: 100%;
		}

		.path {
			stroke-dasharray: $offset;
			stroke-dashoffset: 0;
			transform-origin: center;
			stroke: inherit;
			animation: dash $duration ease-in-out infinite;
		}
	}

	@keyframes rotator {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(270deg);
		}
	}

	@keyframes dash {
		0% {
			stroke-dashoffset: $offset;
		}
		50% {
			stroke-dashoffset: $offset/4;
			transform: rotate(135deg);
		}
		100% {
			stroke-dashoffset: $offset;
			transform: rotate(450deg);
		}
	}
</style>
