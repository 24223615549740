import { ref } from "vue"
import { arrToKeyedObj } from "@/utils"
import api from "./api"

const teams = ref({})
const fetching = ref(false)
const working = ref(false)
const fetchTeams = async (filter = {}) => {
	let { page, perPage, ...qs } = filter
	if (!page) page = 1
	if (!perPage) perPage = 50
	qs.skip = (page - 1) * perPage
	qs.limit = perPage
	fetching.value = true
	working.value = true
	try {
		const result = await api.get("admin/teams/", { qs })
		if (result) {
			const { total, items } = result
			items.forEach((i) => (i.label = `${i.title} ${i.orgTitle}`))
			Object.assign(teams.value, arrToKeyedObj(items))
			return {
				...filter,
				page,
				perPage,
				total,
				items,
				ids: items.map((i) => i.id)
			}
		}
	} finally {
		fetching.value = false
		working.value = false
	}
}
const fetchTeam = async (teamId) => {
	fetching.value = true
	working.value = true
	try {
		const result = await api.get(`admin/teams/${teamId}`)
		if (result) {
			Object.assign(teams.value, arrToKeyedObj(result))
			return result
		}
	} finally {
		fetching.value = false
		working.value = false
	}
}

const init = () => ({
	teams,
	fetchTeams,
	fetchTeam,
	fetching,
	working
})

export default init
