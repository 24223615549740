import { uniq } from "lodash"
import { nanoid } from "nanoid"
import { reactive, computed, readonly } from "vue"
import caller from "@/utils/caller"

const reqs = reactive({})

const errs = computed(() => {
	return uniq(
		Object.values(reqs)
			.filter((req) => req.status === "error")
			.map((req) => req.statusCode)
	)
		.sort((a, b) => a - b)
		.join(",")
})

const working = computed(() => {
	return !!Object.values(reqs).filter((req) => req.status === "pending").length
})

const initReq = (method, route) => {
	const reqId = nanoid()
	const req = { route, method, status: "pending" }
	reqs[reqId] = req
	return reqId
}

const doReq = (method, route, opts) => {
	const reqId = initReq(method, route)
	const req = reqs[reqId]
	return caller[method](route, opts)
		.then((res) => {
			req.status = "done"
			req.statusCode = 200
			return res
		})
		.catch((err) => {
			if (err.name === "AbortError") {
				req.status = "aborted"
			} else {
				req.statusCode = err.response && err.response.status ? err.response.status : 502
				if (req.statusCode == 401) {
					localStorage.setItem("redirect", window.location.href)
					caller.get("saml").then(({ url }) => {
						window.location.href = url
					})
				} else {
					req.status = "error"
					throw err
				}
			}
		})
		.finally(() => {
			setTimeout(() => delete reqs[reqId], 1000)
		})
}

const actions = {
	get: (route, opts) => doReq("get", route, opts),
	post: (route, opts) => doReq("post", route, opts),
	put: (route, opts) => doReq("put", route, opts),
	del: (route, opts) => doReq("del", route, opts)
}

export default {
	reqs: readonly(reqs),
	errs,
	working,
	...actions
}
