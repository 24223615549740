import { ref, computed } from "vue"

const init = () => {
	const total = ref(0)
	const page = ref(1)
	const perPage = ref(50)
	const search = ref("")
	const sortBy = ref("")
	const sortDir = ref("")
	const totalPages = computed(() => Math.ceil(total.value / perPage.value))
	const getQS = ({ page, search }) => {
		const o = {}
		if (page) {
			o.skip = (page - 1) * perPage.value
			o.limit = perPage.value
		}
		if (search) {
			o.search = search
		}
		return o
	}
	return { total, page, perPage, totalPages, search, sortBy, sortDir, getQS }
}

export default init
