/* usage

	open.component({ 
		component, 
		props: { id: userId } 
		size: ''
		width: ''
		height: ''
		sticky: false
	})

	open.dialog({
		header: "",
		body: "",
		buttons: [{
			label: "Ok"
		}],
		size: '' 
		width '',
		height: '',
		sticky: false
	})

*/

import { shallowReactive, ref } from "vue"
import { nanoid } from "nanoid"

const modals = shallowReactive([])

const push = (type, opts) =>
	new Promise((resolve) => {
		opts.size = ref(opts.size)
		modals.unshift({ id: nanoid(), type, resolve, ...opts })
	})

const open = {
	component: (opts) => push("component", opts),
	dialog: (opts) => push("dialog", { ...opts, size: "sm" })
}

const close = (id, result) => {
	const i = modals.findIndex((m) => m.id === id)
	if (i !== -1) {
		modals[i].resolve(result)
		modals.splice(i, 1)
	}
}

export default () => ({
	modals,
	open,
	close
})
