import { ref, computed } from "vue"
import { arrToKeyedObj } from "@/utils"
import api from "./api"

const data = ref([])

const flat = computed(() => {
	return arrToKeyedObj(data.value.map((section) => section.checks.map((check) => check)).flat())
})

const fetch = () =>
	api.get(`admin/exercises/checks`).then((res) => {
		data.value = res
	})

const createItem = (json) => {
	return api.post(`admin/exercises/checks/items`, { json }).then(fetch)
}

const createSection = (json) => {
	return api.post(`admin/exercises/checks/sections`, { json }).then(fetch)
}

const updateItem = (id, title) => {
	const json = { title }
	return api.put(`admin/exercises/checks/items/${id}`, { json }).then(() => {
		flat.value[id].title = title
	})
}

const updateSection = (id, title) => {
	const json = { title }
	return api.put(`admin/exercises/checks/sections/${id}`, { json }).then(() => {
		data.value.find((sections) => sections.id === id).title = title
	})
}

const destroyItem = (id) => {
	return api.del(`admin/exercises/checks/items/${id}`).then(fetch)
}

const destroySection = (id) => {
	return api.del(`admin/exercises/checks/sections/${id}`).then(fetch)
}

export default () => ({
	fetch,
	createItem,
	createSection,
	updateItem,
	updateSection,
	destroyItem,
	destroySection,
	data,
	flat
})
