import { uniq, without, pick } from "lodash"
import { ref } from "vue"
import { arrToKeyedObj, Upload } from "@/utils"
import api from "./api"

const exercises = ref({})
const fetching = ref(false)
const creating = ref(false)
const updating = ref(false)
const working = ref(false)
const uploading = ref(false)

const addToStore = (exercise, lang) => {
	exercise.lang = lang
	Object.assign(exercises.value, arrToKeyedObj(exercise))
	return exercise
}

const fetchExercise = async (exerciseId, lang = "en") => {
	fetching.value = true
	working.value = true
	try {
		const result = await api.get(`admin/exercises/${exerciseId}/${lang}`)
		if (result) return addToStore(result, lang)
	} finally {
		fetching.value = false
		working.value = false
	}
}
const createExercise = async (json) => {
	creating.value = true
	working.value = true
	try {
		const result = await api.post(`admin/exercises/en`, { json })
		exercises.value[result.id] = result
		return result
	} finally {
		creating.value = false
		working.value = false
	}
}
const updateExercise = async (exerciseId, meta) => {
	updating.value = true
	working.value = true
	try {
		const exercise = exercises.value[exerciseId]
		const json = pick(exercise, ["thumbnailId", "title", "weight", "title", "instructions"])
		json.resources = exercise.resources.map((r) => pick(r, ["id", "resourceId", "caption"]))
		json.meta = meta
		const result = await api.put(`admin/exercises/${exerciseId}/${exercise.lang}`, { json })
		if (result) return addToStore(result, exercise.lang)
	} finally {
		updating.value = false
		working.value = false
	}
}
const uploadThumbnail = async (exerciseId, files) => {
	uploading.value = true
	try {
		const file = files[0]
		const upload = new Upload(file, "thumb")
		const path = exercises.value[exerciseId]
		upload.addEventListener("status", ({ detail }) => {
			path.uploadStatus = detail.msg
			if (detail.msg === "Done") {
				path.thumbnailId = detail.data.id
				path.thumbnail = detail.data
			}
		})
		await upload.start()
	} finally {
		uploading.value = false
	}
}
const uploadResources = async (exerciseId, files) => {
	uploading.value = true
	for (const file of files) {
		const resource = {}
		const length = exercises.value[exerciseId].resources.push(resource)
		const path = exercises.value[exerciseId].resources[length - 1]
		try {
			const upload = new Upload(file)
			upload.addEventListener("status", ({ detail }) => {
				path.uploadStatus = detail.msg
				if (detail.msg === "Done") {
					path.resourceId = detail.data.id
					path.resource = detail.data
				}
			})
			await upload.start()
		} catch (err) {
			exercises.value[exerciseId].resources.pop()
			console.log(err)
		}
	}
	uploading.value = false
}

const init = () => ({
	exercises,
	fetchExercise,
	createExercise,
	updateExercise,
	fetching,
	creating,
	updating,
	uploading,
	working,
	uploadResources,
	uploadThumbnail
})

export default init
