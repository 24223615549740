import { ref } from "vue"
import { arrToKeyedObj } from "@/utils"
import api from "./api"

const orgs = ref({})
const fetching = ref(false)
const updating = ref(false)
const creating = ref(false)
const working = ref(false)
const fetchOrgs = async (filter = {}) => {
	let { page, perPage, ...qs } = filter
	if (!page) page = 1
	if (!perPage) perPage = 50
	qs.skip = (page - 1) * perPage
	qs.limit = perPage
	fetching.value = true
	working.value = true
	try {
		const result = await api.get("admin/organizations/", { qs })
		if (result) {
			const { total, items } = result
			Object.assign(orgs.value, arrToKeyedObj(items))
			return {
				...filter,
				page,
				perPage,
				total,
				items,
				ids: items.map((i) => i.id)
			}
		}
	} finally {
		fetching.value = false
		working.value = false
	}
}
const fetchOrg = async (orgId) => {
	fetching.value = true
	working.value = true
	try {
		const result = await api.get(`admin/organizations/${orgId}`)
		if (result) {
			Object.assign(orgs.value, arrToKeyedObj(result))
			return result
		}
	} finally {
		fetching.value = false
		working.value = false
	}
}
const updateOrg = async (orgId, json) => {
	updating.value = orgId
	working.value = true
	try {
		const result = await api.put(`admin/organizations/${orgId}`, { json })
		orgs.value[orgId] = result
		return result
	} finally {
		updating.value = false
		working.value = false
	}
}
const deleteOrg = async (orgId) => {
	updating.value = orgId
	working.value = true
	try {
		const result = await api.del(`admin/organizations/${orgId}`)
		if (result) {
			delete orgs.value[orgId]
			return orgId
		}
	} finally {
		updating.value = false
		working.value = false
	}
}
const createOrg = async () => {
	creating.value = true
	working.value = true
	try {
		const json = { title: "Untitled Organization", seats: 15 }
		const result = await api.post(`admin/organizations`, { json })
		orgs.value[result.id] = result
		return result
	} finally {
		creating.value = false
		working.value = false
	}
}

const init = () => ({
	orgs,
	fetchOrgs,
	fetchOrg,
	updateOrg,
	createOrg,
	deleteOrg,
	fetching,
	updating,
	creating,
	working
})

export default init
