<template>
	<div class="modalsWrapper" :class="{ active: modals.length }">
		<transition-group name="modalTransition" tag="div">
			<div
				v-for="({ id, type, ...opts }, index) in modals"
				:key="id"
				@click="close(id)"
				:style="`z-index: ${modals.length - index}`"
				class="modalWrapper"
			>
				<div
					class="modalContainer"
					@click.stop
					:class="[{ dialog: type === 'dialog' }, opts.size.value]"
					:style="getStyle(opts)"
				>
					<component
						v-if="type === 'component'"
						:is="opts.component"
						v-bind="opts.props"
						@close="(result) => close(id, result)"
					/>
					<template v-else-if="type === 'dialog'">
						<div v-if="opts.header" class="header" v-html="opts.header" />
						<div v-if="opts.body" class="body" v-html="opts.body" />
						<div class="footer">
							<template v-if="opts.buttons">
								<button
									v-for="({ label }, index) in opts.buttons"
									:key="index"
									@click="close(id, index)"
								>
									{{ label }}
								</button>
							</template>
							<button v-else @click="close(id)">Ok</button>
						</div>
					</template>
				</div>
			</div>
		</transition-group>
	</div>
</template>

<script>
	import { modals } from "@/store"

	export default {
		name: "Modals",
		setup() {
			const getStyle = ({ width, height }) => {
				let str = ""
				if (width) str += `width: ${width}; `
				if (height) str += `height: ${height}; `
				return str
			}
			return {
				...modals(),
				getStyle
			}
		}
	}
</script>

<style lang="scss">
	.modalsWrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100000;
		transition: background linear 200ms;
		&.active {
			background: rgba(0, 0, 0, 0.2);
		}
		&:not(.active) {
			pointer-events: none;
		}

		.modalWrapper {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			transition: transform 0.6s;
			overflow: scroll;
			& + .modalWrapper {
				transform: scale(0.9);
			}
		}

		.modalContainer {
			margin: auto;
			display: grid;
			grid-template-rows: auto 1fr;
			background: #fff;
			box-shadow: 0 40px 60px 10px rgba(0, 0, 0, 0.3);
			&.sm {
				width: 640px;
				min-height: 320px;
				border-radius: $atom * 2;
				padding: $atom * 4;
			}
			&.md {
				width: 85%;
				min-height: 85%;
				border-radius: 8px;
				padding: $atom * 4;
			}
			&.lg {
				width: 100%;
				height: 100%;
			}
		}

		/* for when doing the app update, move dialog into its own component */

		.dialog {
		}

		.header {
			position: sticky;
			top: 0;
			height: $ctrl-ht;
			font-size: $fnt-lrg;
			color: $clr-prim;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #fff;
			z-index: 10;
		}

		.body {
			position: relative;
			z-index: 1;
			padding: $atom * 4 0;
		}

		.footer {
			position: sticky;
			bottom: 0;
			height: $ctrl-ht;
			display: flex;
			background: #fff;
			button {
				flex: 1;
			}
			z-index: 10;
		}

		.modalTransition-enter-active {
			transition: transform 0.6s $ease-out-cubic, opacity 0.6s;
			> div {
				transition: transform 0.6s $ease-in-quad;
			}
		}
		.modalTransition-leave-active {
			transition: transform 0.6s $ease-in-quad, opacity 0.6s;
			> div {
				transition: transform 0.6s $ease-out-quad;
			}
		}
		.modalTransition-enter-from,
		.modalTransition-leave-to {
			opacity: 0;
			transform: translateY(100px);
			> div {
				transform: scale(0.9);
			}
		}
	}
</style>
