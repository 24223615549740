import { pick } from "lodash"
import { ref, computed } from "vue"
import { nanoid } from "nanoid"
import { resources as _resources } from "./"

const init = (...items) => {
	const resources = _resources()
	const captions = ref({})
	const listIds = ref([])
	const uploading = ref(false)
	const push = (...items) => {
		if (!items.length) items.push({})
		const cids = []
		items.forEach((item) => {
			const cid = nanoid()
			captions.value[cid] = { cid, ...pick(item, ["id", "resourceId", "caption"]) }
			listIds.value.push(cid)
			cids.push(cid)
		})
		return cids
	}
	const pop = (cid) => {
		const i = listIds.value.indexOf(cid)
		if (i > -1) {
			listIds.value.splice(i, 1)
			delete captions.value[cid]
		}
	}
	const clear = () => {
		listIds.value = []
		captions.value = {}
	}
	const upload = async (files) => {
		uploading.value = true
		try {
			for (const file of files) {
				const cid = push()[0]
				const upload = resources.upload(file)
				upload.addEventListener("status", ({ detail }) => {
					captions.value[cid].uploadStatus = detail.msg
					if (detail.msg === "Done") captions.value[cid].resourceId = detail.data.id
				})
				await upload.start().catch(() => pop(cid))
			}
		} finally {
			uploading.value = false
		}
	}
	if (items.length) push(...items)
	return {
		listIds,
		items: computed(() => listIds.value.map((cid) => captions.value[cid])),
		push,
		pop,
		clear,
		upload,
		uploading,
		resources
	}
}

export default init
