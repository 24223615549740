import { pick } from "lodash"
import { ref, watch } from "vue"
import { api, resourceCaptions } from "./"

const init = () => {
	let stringified
	const captions = resourceCaptions()
	const attrs = ref({
		id: null,
		lang: "",
		created: null,
		updated: null,
		visible: false,
		title: "",
		instructions: "",
		captions: captions.items
	})
	const unsaved = ref(false)
	const fetching = ref(false)
	const saving = ref(false)
	const fetch = ({ id, lang }) => {
		fetching.value = true
		return api
			.get(`admin/education/${id}/${lang}`)
			.then((res) => parseResult(res, lang))
			.catch(() => {})
			.finally(() => (fetching.value = false))
	}
	const save = () => {
		const json = {
			...pick(attrs.value, ["visible", "title", "instructions"]),
			resources: attrs.value.captions.map((r) => pick(r, ["id", "resourceId", "caption"]))
		}
		saving.value = true
		return api
			.put(`admin/education/${attrs.value.id}/${attrs.value.lang}`, { json })
			.then((education) => parseResult({ education }))
			.catch(() => {})
			.finally(() => (saving.value = false))
	}
	const parseResult = ({ education, resources }, lang) => {
		if (lang) attrs.value.lang = lang
		attrs.value.id = education.id
		attrs.value.created = education.created
		attrs.value.updated = education.updated
		attrs.value.visible = education.visible
		attrs.value.title = education.title
		attrs.value.instructions = education.instructions
		if (resources) captions.resources.push(...resources)
		captions.clear()
		if (education.resources.length) captions.push(...education.resources)
		stringified = JSON.stringify(attrs.value)
		unsaved.value = false
	}
	watch(attrs.value, (value) => {
		if (!unsaved.value && JSON.stringify(value) !== stringified) unsaved.value = true
	})
	return {
		attrs,
		captions,
		unsaved,
		fetch,
		save,
		fetching,
		saving
	}
}

export default init
