import { uniq, without } from "lodash"
import { ref, readonly } from "vue"

const init = (multiple = true) => {
	const selectedIds = ref([])
	const pushSelected = (...ids) => {
		selectedIds.value = multiple ? uniq([...ids, ...selectedIds.value]) : [ids[0]]
	}
	const popSelected = (...ids) => {
		selectedIds.value = without(selectedIds.value, ...ids)
	}
	const toggleSelected = (id) => {
		selectedIds.value.includes(id) ? popSelected(id) : pushSelected(id)
	}
	const clearSelected = () => {
		selectedIds.value = []
	}
	const isSelected = (id) => selectedIds.value.includes(id)
	return {
		selectedIds: readonly(selectedIds),
		pushSelected,
		popSelected,
		toggleSelected,
		clearSelected,
		isSelected
	}
}

export default init
