<template>
	<div class="rich">
		<div v-if="editor" class="ctrls pa-2">
			<button
				@click="editor.chain().focus().toggleBold().run()"
				:class="{ active: editor.isActive('bold') }"
				tabindex="-1"
			>
				B
			</button>
			<button
				@click="editor.chain().focus().toggleItalic().run()"
				:class="{ active: editor.isActive('italic') }"
				tabindex="-1"
			>
				I
			</button>
			<button
				@click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
				:class="{ active: editor.isActive('heading', { level: 1 }) }"
				tabindex="-1"
			>
				H1
			</button>
			<button
				@click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
				:class="{ active: editor.isActive('heading', { level: 2 }) }"
				tabindex="-1"
			>
				H2
			</button>
			<button
				@click="editor.chain().focus().toggleBulletList().run()"
				:class="{ active: editor.isActive('bulletList') }"
				tabindex="-1"
			>
				Ul
			</button>
			<button
				@click="editor.chain().focus().toggleOrderedList().run()"
				:class="{ active: editor.isActive('orderedList') }"
				tabindex="-1"
			>
				Ol
			</button>
			<button @click="editor.chain().focus().setHorizontalRule().run()" tabindex="-1">--</button>
			<button
				@click="editor.chain().focus().setTextAlign('left').run()"
				:class="{ active: editor.isActive({ textAlign: 'left' }) }"
				tabindex="-1"
			>
				Lft
			</button>
			<button
				@click="editor.chain().focus().setTextAlign('center').run()"
				:class="{ active: editor.isActive({ textAlign: 'center' }) }"
				tabindex="-1"
			>
				Cntr
			</button>
		</div>
		<editor-content :editor="editor" class="editor" />
	</div>
</template>

<script>
	import { onMounted, watch } from "vue"
	import { useEditor, EditorContent } from "@tiptap/vue-3"
	import StarterKit from "@tiptap/starter-kit"
	import TextAlign from "@tiptap/extension-text-align"

	export default {
		components: {
			EditorContent
		},
		props: {
			modelValue: {
				type: String,
				default: ""
			}
		},
		setup(props, context) {
			const editor = useEditor({
				content: props.modelValue,
				extensions: [
					StarterKit,
					TextAlign.configure({
						types: ["heading", "paragraph"]
					})
				],
				onUpdate: ({ editor }) => context.emit("update:modelValue", editor.getHTML())
			})
			watch(
				() => props.modelValue,
				(val) => {
					if (editor.value.getHTML() !== val) editor.value.commands.setContent(val, false)
				}
			)
			return { editor }
		}
	}
</script>

<style lang="scss">
	.rich {
		overflow: hidden;
		.ctrls {
			background: #fff;
			padding: 0;
		}
		.editor > div {
			@include pa(4);
		}
		.active {
			@include bgGrdPos(true);
		}
		h1 {
			font-size: $fnt-hdr;
			font-weight: bold;
		}
		h2 {
			font-size: $fnt-lrg;
			font-weight: bold;
			color: $clr-prim;
		}
		ul,
		ol {
			@include pl(5);
		}
		h1,
		h2,
		p,
		ul,
		ol {
			@include mb(5);
			&:not(:first-child) {
				@include mt(5);
			}
		}
		li p {
			margin: 0;
		}
		a {
			color: $clr-prim;
			font-weight: bold;
		}
		hr {
			border: 0;
			border-top: 1px solid $clr-brdr;
		}
		> *:last-child {
			margin-bottom: 0;
		}
		b,
		strong {
			color: $clr-prim;
		}
	}
</style>
