<template>
	<div class="stickySweet">
		<div class="fill" ref="fill" />
		<div class="sticky" ref="sticky">
			<div class="atTop" ref="atTop" />
			<div class="atBottom" ref="atBottom" />
			<div class="contentWrapper" ref="contentWrapper"><slot /></div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "StickySweet",
		props: ["containerClass"],
		data: () => ({
			atTop: false,
			atBottom: null,
			dir: null,
			lst: 0,
			iObserver: null,
			rObserver: null,
			container: null
		}),
		methods: {
			onScroll() {
				if (this.container.scrollTop > this.lst) this.dir = "down"
				else this.dir = "up"
				this.lst = this.container.scrollTop
			},
			onResize() {
				this.$refs.contentWrapper.style.minHeight = this.container.offsetHeight + 1 + "px"
				this.onScroll()
			},
			calc(dir) {
				const fill = this.$refs.fill
				const sticky = this.$refs.sticky
				const rSticky = sticky.getBoundingClientRect()
				const rContainer = this.container.getBoundingClientRect()
				const h = rContainer.height - rSticky.height
				if (h > 0) return (sticky.style.top = 0)
				if (dir === "up") {
					sticky.style.top = null
					sticky.style.bottom = `${h}px`
					if (this.atBottom)
						fill.style.height = `${Math.max(0, this.lst + rContainer.height - rSticky.height)}px`
				} else {
					sticky.style.bottom = null
					sticky.style.top = `${h}px`
					if (this.atTop) fill.style.height = `${this.lst}px`
				}
			}
		},
		watch: {
			dir(a, b) {
				if (a && a !== b) this.calc(a)
			}
		},
		mounted() {
			this.iObserver = new IntersectionObserver(
				(entries) => {
					entries.forEach((e) => {
						if (e.target.classList.contains("atTop")) this.atTop = e.intersectionRatio >= 1
						else if (e.target.classList.contains("atBottom")) this.atBottom = e.intersectionRatio >= 1
					})
				},
				{ threshold: [1] }
			)
			this.iObserver.observe(this.$refs.atTop)
			this.iObserver.observe(this.$refs.atBottom)
			this.container = this.$el.closest(`.${this.containerClass}`)
			this.container.addEventListener("scroll", this.onScroll)
			this.rObserver = new ResizeObserver(this.onResize)
			this.rObserver.observe(this.container)
			this.onResize()
			this.calc("up")
		},
		beforeUnmount() {
			this.iObserver.disconnect()
			this.rObserver.disconnect()
			this.container.removeEventListener("scroll", this.onScroll)
		}
	}
</script>

<style lang="scss">
	.stickySweet {
		position: relative;
		.sticky {
			position: sticky;
			.atTop {
				position: absolute;
				top: 0;
				left: 50%;
			}
			.atBottom {
				position: absolute;
				bottom: 0;
				left: 50%;
			}
		}
	}
</style>
