<template>
	<div ref="root" class="dataTableWrapper">
		<table v-if="items.length" class="dataTable">
			<thead>
				<slot name="thead" />
			</thead>
			<tbody>
				<slot name="tbody" v-for="(item, i) in items" :key="i" :item="item" />
			</tbody>
		</table>
		<div v-else class="noResults pa-5">
			<slot name="noResults" />
		</div>
		<slot name="tfoot" />
	</div>
</template>

<script>
	import { ref, watch, nextTick, onMounted } from "vue"

	export default {
		name: "DataTable",
		props: { items: Array, stickyCol: Number },
		setup(props) {
			const root = ref(null)
			const ht = ref(0)
			// note to future trav -- this should just listen to a resize event on the window
			watch(
				() => props.items,
				(value) => {
					if (value.length) {
						nextTick(() => {
							ht.value = `${root.value.offsetHeight}px`
							console.log(ht.value)
							const el = root.value.querySelector("th.stickLeft")
							if (el) {
								const observer = new IntersectionObserver(
									([e]) => e.target.classList.toggle("stuck", e.intersectionRatio < 1),
									{ threshold: [0, 1] }
								)
								observer.observe(el)
							}
						})
					}
				}
			)
			return { root, ht }
		}
	}
</script>

<style lang="scss">
	.dataTableWrapper {
		border-radius: $rds-sml;
		width: 100%;
		overflow: scroll;
		background: #fff;
		table {
			border-spacing: 0;
			border-collapse: separate;
			width: 100%;
			background: #fff;
		}
		tr {
			&:hover:not(.selected):not(.disabled) {
				td {
					@include hvrDk;
				}
			}
			&.selected {
				td {
					background: $clr-fcs;
				}
			}
		}
		th,
		td {
			height: $ctrl-ht;
			text-align: left;
			@include ph(5);
			@include pv(3);
			&:not(:last-child) {
				@include br;
			}
			> * {
				vertical-align: middle;
			}
		}
		th {
			position: sticky;
			top: 0;
			background: #fff;
		}
		/* horizontal sticky */
		td.stickLeft {
			position: sticky;
			left: -1px;
			z-index: 1;
			background: #fff;
		}
		th.stickLeft {
			left: -1px;
			z-index: 2;
			&:after {
				@include absTl;
				opacity: 0;
				transition: opacity 200ms;
				width: 100%;
				height: v-bind(ht);
				box-shadow: 8px 0 8px rgba(0, 0, 0, 0.2);
				content: "";
				pointer-events: none;
			}
			&.stuck:after {
				opacity: 1;
			}
		}
		/* column types */
		.min {
			width: 0;
			white-space: nowrap;
		}
		.ts {
			width: 0;
			white-space: nowrap;
			font-size: $fnt-sml;
		}
	}
</style>
