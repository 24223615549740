<template>
	<button class="richButton" :class="classes">
		<Spinner v-if="working && !disabled" />
		<span class="slotWrapper" :class="{ working }">
			<slot />
		</span>
	</button>
</template>

<script>
	import { computed } from "vue"
	import Spinner from "./Spinner"

	export default {
		name: "Richbutton",
		components: { Spinner },
		props: {
			working: { type: Boolean, default: false },
			disabled: { type: Boolean, default: false },
			theme: { type: String, default: "" }
		},
		setup(props) {
			const classes = computed(() => {
				const list = []
				switch (props.theme) {
					case "prim":
						list.push("bgGrdPrim")
						break
					case "acc":
						list.push("bgGrdAcc")
						break
					case "warn":
						list.push("bgGrdWarn")
						break
					case "pos":
						list.push("bgGrdPos")
						break
					case "neg":
						list.push("bgGrdNeg")
				}
				if (props.working) list.push("working")
				if (props.disabled) list.push("disabled")
				return list.join(" ")
			})
			return { classes }
		}
	}
</script>

<style lang="scss">
	.richButton {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: $ctrl-ht;
		background: $clr-neut-l1;
		cursor: pointer;
		@include ph(4);
		&:not(.working):hover {
			@include hvrLt;
		}
		&.disabled {
			pointer-events: none;
			opacity: 0.4;
		}
		&.bgGrdPrim,
		&.bgGrdAcc,
		&.bgGrdWarn,
		&.bgGrdPos,
		&.bgGrdNeg {
			border: none !important;
			.spinner {
				stroke: #fff;
			}
		}
		.slotWrapper {
			visibility: visible;
		}
		.working {
			visibility: hidden;
		}
		.spinner {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -12px;
			margin-left: -12px;
		}
	}
</style>
