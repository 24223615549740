import { ref } from "vue"
import { nanoid } from "nanoid"

const list = ref([])
const push = (msg, theme) => {
	list.value.push({ id: nanoid(), msg, theme })
	setTimeout(() => list.value.shift(), 3000)
}

export default () => ({
	list,
	push
})
