import { createApp } from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import profile from "@/store/profile"

profile()
	.fetch()
	.then(() => createApp(App).use(router).mount("#app"))
	.catch(console.log)
//.catch(() => (window.location.href = "https://simpleset.net"))
