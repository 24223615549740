<template>
	<div class="contextMenuWrapper" @click.stop="close">
		<div :style="style" ref="menu" @mouseleave="onMouseLeave" @mouseenter="onMouseEnter"><slot /></div>
	</div>
</template>

<script>
	export default {
		name: "ContextMenu",
		props: ["pos", "persist"],
		data() {
			return {
				x: 0,
				y: 0,
				timer: null
			}
		},
		computed: {
			style() {
				return `top: ${this.y}px; left: ${this.x}px`
			}
		},
		methods: {
			onMouseEnter() {
				clearTimeout(this.timer)
			},
			onMouseLeave() {
				if (!this.persist) this.timer = setTimeout(this.close, 2000)
			},
			close() {
				clearTimeout(this.timer)
				this.$emit("close")
			}
		},
		mounted() {
			if (this.pos.el) {
				const el = this.pos.el.getBoundingClientRect()
				const menu = this.$refs.menu.getBoundingClientRect()
				this.y = el.top + el.bottom
				let x
				if (this.pos.align === "center") {
					x = el.x + el.width / 2 - menu.width / 2
				} else if (this.pos.align === "right") {
					// placeholder
				} else {
					// default left
					x = el.x
				}
				if (x < 0) x = 0
				else if (x + menu.width > this.$el.offsetWidth) x = this.$el.offsetWidth - menu.width + 1
				this.x = x
			} else if (this.pos.x && this.pos.y) {
				const wrapper = this.$el.getBoundingClientRect()
				const menu = this.$refs.menu.getBoundingClientRect()
				const x = this.pos.x - wrapper.left - 20
				const y = this.pos.y - wrapper.top - 20
				this.x = x - Math.max(x + menu.width - wrapper.width, 0)
				this.y = y - Math.max(y + menu.height - wrapper.height, 0)
			} else {
				this.close()
			}
		}
	}
</script>

<style lang="scss">
	.contextMenuWrapper {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: 100000;
		> div {
			position: absolute;
			background: #fff;
			border: 1px solid $clr-brdr;
			box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.08);
			transform-origin: 0 0;
			padding: $atom * 2;

			> header {
				background: $clr-neut-l2;
				font-weight: bold;
				color: $clr-prim;
				padding: $atom * 4 $atom * 6;
			}

			> button {
				display: block;
				width: 100%;
				background: none;
				padding: 0 $atom * 6;
				height: 32px;
				text-align: left;
				&:hover {
					background: $grd-acc;
					color: #fff;
				}
			}
		}
	}
</style>
